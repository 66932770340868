<template>
  <el-card shadow="hover" v-loading="loading" class="x-card-title" header="方案详情">
    <template slot="header">
      <span>方案详情</span>
      <div class="buttonbc">
      <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
        <img src="@/assets/back.png" alt="">
      </el-button>
      </div>
    </template>
    
    <el-form>
      <el-form-item label="方案名称">
        {{ orderDetail.title }}
      </el-form-item>

      <el-form-item label="创建时间">
        {{ orderDetail.create_time | unixToDate }}
      </el-form-item>

      <el-form-item label="创建人员">
        销售部-{{ orderDetail.nickname }}
      </el-form-item>

      <el-form-item label="套餐总金额">
        {{ orderDetail.price | unitPrice('¥') }}
      </el-form-item>

      <el-form-item label="套餐方案描述">
        {{ orderDetail.detail }}
      </el-form-item>

      <el-form-item label="套餐方案商品">
        <en-table-layout
          style="width: 70vw; display: inline-block"
          :toolbar="false"
          :pagination="false"
          :table-data="pageData.data"
        >
          <template slot="table-columns">
            <el-table-column label="商品图片" prop="thumbnail" width="100">
              <template slot-scope="scope">
                <img :src="scope.row.thumbnail" class="goods-image" alt=""/>
              </template>
            </el-table-column>
            <el-table-column label="商品名称" prop="goods_name" width="300"></el-table-column>
            <el-table-column label="市场价" prop="mktprice">
              <template slot-scope="scope">{{ scope.row.mktprice | unitPrice('￥') }}</template>
            </el-table-column>
            <el-table-column label="分销价" prop="VipPrice">
              <template slot-scope="scope">{{ scope.row.enterprise_revise_price | unitPrice('￥') }}</template>
            </el-table-column>
            <el-table-column label="销售价" prop="revise_price">
              <template slot-scope="scope">{{ scope.row.revise_price | unitPrice('￥') }}</template>
            </el-table-column>
          </template>
        </en-table-layout>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import * as API_order from '@/api/order';
import * as API_Shop from '@/api/shop';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'programmeDetail',
  components: {EnTableLayout},
  data() {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 订单详情数据 */
      orderDetail: {},

      /* 商品信息数组 */
      pageData: {data: []},

      id: '',

      route: ''
    };
  },
  filters: {},
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.GET_Detail();
    next();
  },
  mounted() {
    this.id = this.$route.params.id;
    this.GET_Detail();
  },
  activated() {
    this.id = this.$route.params.id;
    this.GET_Detail();
  },
  methods: {
    /** 获取订单详情信息 */
    GET_Detail() {
      this.loading = true;
      API_Shop.getgoodsMktDet(this.id).then(res => {
        this.orderDetail = res;
        this.pageData.data = (res.goods_mkt_items_vos);
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.goods-image {
  width: 50px;
  height: 50px;
}
// .x-card-title {
//   position: relative;
// }
.buttonbc {
  position: absolute;
  top: 7px;
  right: 20px;
}

/deep/ {
  .el-form-item {
    margin: 0;
  }
}
</style>

